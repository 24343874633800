export async function validatePhoneNumber(phone) {

    const numericPhone = phone.replace(/\D/g, ""); // Extract digits
    const API_KEY = '35d35dd2041542e1ad0580f9ebc9c1fc';
    const url = `https://phonevalidation.abstractapi.com/v1/?api_key=${API_KEY}&phone=${numericPhone}`;
  
    try {

      // await new Promise((resolve) => setTimeout(resolve, 30000));
      // return false;

      const response = await fetch(url);
      const data = await response.json();
      // Check if the phone is valid and belongs to Canada
      return data.valid && data.country?.code === "CA";
    } catch (error) {
      console.error("Phone validation failed:", error);
      return false; // Default to invalid on error
    }
  }
  