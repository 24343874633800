/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation, useParams } from "react-router";
import { history } from "../../_helpers";
import AddressStep from "./components/steps/AddressStep";
import BirthDayStep from "./components/steps/BirthDayStep";
import CongratulationStep from "./components/steps/CongratulationStep";
import { formStateInit } from "./components/constant";
import EmailDetails from "./components/steps/EmailDetails";
import EmploymentStatus from "./components/steps/EmploymentStatus";
import EmploymentType from "./components/steps/EmploymentType";
import MonthlyIncome from "./components/steps/MonthlyIncome";
import PersonlDetail from "./components/steps/PersonalDetail";
import SelectVehicle from "./components/steps/SelectVehicle";
import TostarMessages from "../../components/alertMessages/TostarMessages";
import { useDispatch, useSelector } from "react-redux";
import { get_ad_post_detail } from "../../actions/postDetailActions";

export default function PostApplicationV2() {
  // state
  const totalSteps = 9; // Adjusted total steps after removing one
  const [step, setStep] = useState(1);
  const [url, setUrl] = useState("");
  const [formState, setFormState] = useState(formStateInit);
  const dispatch = useDispatch()
  const { add_post_detail } = useSelector((state) => state.adPostReducers.postDetailReducer)
  // query
  const routerState = useLocation()?.state
  const { stockNumber } = useParams()


  // path
  const path = useLocation().pathname;

  // routes
  const routes = [
    {
      Component: SelectVehicle,
      step: 1,
    },
    {
      Component: EmploymentStatus,
      step: 2,
    },
    {
      Component: EmploymentType,
      step: 3,
    },
    {
      Component: MonthlyIncome,
      step: 4,
    },
    {
      Component: AddressStep,
      step: 5,
    },
    {
      Component: BirthDayStep,
      step: 6,
    },
    {
      Component: PersonlDetail,
      step: 7,
    },
    {
      Component: EmailDetails,
      step: 8,
    },
    {
      Component: CongratulationStep,
      step: 9,
    },
  ];

  // redirecting on mount
  useEffect(() => {
    const pathArr = path.split("/");
    const mainUrl = pathArr.length > 4 ? pathArr.slice(0, -1).join("/") : path;
    if (!url) {
      setUrl(mainUrl);
      history.push({
        pathname: `${mainUrl}/${routerState?.step || 1}`,
        state: routerState
      });
    }

    return () => { };
  }, []);

  useEffect(() => {
    dispatch(get_ad_post_detail(stockNumber))
  }, [stockNumber])

  useEffect(() => {
    const vehicleData = add_post_detail?.[0] || {}
    setFormState({
      ...formState, interested_vehicle_type: vehicleData?.category?.name || "", stock: vehicleData?.stock_id || "", vehicle: [{
        type_of_vehicle: vehicleData?.category?.id || "",
        type_of_vehicle_name: vehicleData?.category?.name || "",
        sub_type_of_vehicle: vehicleData?.sub_type_id?.id || "",
        sub_type_of_vehicle_name: vehicleData?.sub_type_id?.name || "",
        year: vehicleData?.year || "",
        make: vehicleData?.make || "",
        model: vehicleData?.model || "",
        vin: vehicleData?.vin || "",
        price: vehicleData?.price || "",
        condition: vehicleData?.v_condition || "",
        trim: vehicleData?.trim || "",
        kilometer: vehicleData?.kilometer || "",
        stock_id: vehicleData?.stock_id || ""
      }], user_id: vehicleData?.user_id?.id || ""
    })
  }, [add_post_detail])

  useEffect(() => {

    const pathArr = path.split("/");

    if (routerState && routerState !== undefined) {
      setFormState({ ...formState, interested_vehicle_type: routerState?.category_name || "", stock: routerState?.stock || "", vehicle: [routerState?.vehicle || ""], user_id: routerState?.user_id || "" })
    }
    if (pathArr.length > 4) {
      const nextStep = +pathArr[4];
      const step = +routerState?.step || nextStep

      setStep(step);
    }
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    return () => { };
  }, [path]);

  // on form state change
  const onChange = (e) => {
    setFormState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // on Continue
  const onContinue = (paramStep) => {


    if (paramStep) {
      history.push(
        `${url}/${paramStep}`
      );
      return;
    }
    history.push(
      `${url}/${Number(step) + 1}`
    );
  };

  // main return
  return (
    <section className="postAppQuick">
      <div className="formDiv">
        <div className="innerDiv">
          <div className="progressBar">
            <div
              className="filled"
              style={{ width: `${(step / totalSteps) * 100}%` }}
            ></div>
          </div>
          <div>
            <Switch>
              {routes.map(({ Component, step }) => {
                return (
                  <Route
                    path={`${url}/${step}`}
                    render={(props) => (
                      <Component
                        {...props}
                        formState={formState}
                        setFormState={setFormState}
                        onChange={onChange}
                        onContinue={onContinue}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </div>
      <TostarMessages />
    </section>
  );
}
