import React from "react";
import {
  employmentTypeEmployed,
  employmentTypeUnemployed,
} from "../constant";;

/**
 * Component dynamically renders options based on employment status.
 * - If "employed", loops through `employmentTypeEmployed`.
 * - If "unemployed", loops through `employmentTypeUnemployed`.
 */

export default function EmploymentType({ formState, setFormState, onContinue }) {
  // Select options based on employment status
  const isEmployed = formState.employement_status === "Employed";
  const options = isEmployed ? employmentTypeEmployed : employmentTypeUnemployed;

  const handleSelection = (value) => {
    setFormState((prev) => ({
      ...prev,
      type_of_employment: value,
    }));
    onContinue();
  };

  return (
    <div className="formWrapper">
      <h1>
        {isEmployed
          ? "What is your employment status?"
          : "What is your unemployment status?"}
      </h1>
      <div className="optionFlex">
        {options.map((item) => (
          <div
            key={item.value}
            className={`item ${
              formState.type_of_employment === item.value ? "active" : ""
            }`}
            onClick={() => handleSelection(item.value)}
            style={{ justifyContent: "center" }}
          >
            {item.label}
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          if (formState.type_of_employment) {
            onContinue();
          }
        }}
        className="primaryButton continueBtn"
      >
        <span></span> <span>Continue</span>
        <img src="/assets/image/arrow_circle_right_outline.svg" alt="icon" />
      </button>
    </div>
  );
}
