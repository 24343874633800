import React from "react";
import { Formik, Form, Field } from "formik";
import { TextInput } from "../formInputs";
import * as Yup from "yup";
export default function MonthlyIncome({ formState, setFormState, onContinue }) {

  // validation Schema
  const ValidationSchema = Yup.object().shape({
    monthly_income: Yup.number()
      .positive("Monthly income must be greater than zero")
      .required("Monthly income is required"),
  });

  // onSubmit
  const onSubmit = (formValues) => {
    setFormState((prev) => {
      return { ...prev, ...formValues };
    });
    onContinue();
  };

  return (
    <div className="formWrapper">
      <h1>What is your net monthly income?</h1>

      <Formik
        initialValues={{
          monthly_income: formState.monthly_income || ""
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="optionFlex">
            <Field
              name="monthly_income"
              type="number"
              component={TextInput}
              placeholder="Net Monthly Income"
              style={{ width: "100%", maxWidth: "620px" }}
            />
          </div>
          <button type="submit" className="primaryButton continueBtn">
            <span></span> <span>Continue</span>
            <img
              src="/assets/image/arrow_circle_right_outline.svg"
              alt="icon"
            />
          </button>
        </Form>
      </Formik>
    </div>
  );
}
