/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TextInput, TelephoneMaskedInput } from "../formInputs";
import * as Yup from "yup";
import { save_post_application } from "../../../../actions/postApplication";

import { validatePhoneNumber } from "../../../../../src/utils/validatePhoneNumber";
import LoadingOverlay from 'react-loading-overlay';

export default function EmailDetails({ formState, setFormState, onContinue }) {

  const [isSubmitting, setIsSubmitting] = useState(false)

  // redux state
  const { otp_loading } = useSelector(
    (state) => state.authReducer.authentication
  );

  const { user_id } = useSelector(
    (state) => state.authReducer.authentication.user
  );

  const { isLoading, save_post } = useSelector(
    (state) => state.postApplication.postApplicationReducer
  );

  const dispatch = useDispatch();

  // validation Schema
  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    telephone: Yup.string()
      .required("Required")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Please enter all 10 digits"
      ),
  });

  // onSubmit
  const onSubmit = async (formValues, { setFieldError }) => {

    try {

      setIsSubmitting(true);

      const phoneNumber = `+1${formValues.telephone}`;
      const isPhoneValid = await validatePhoneNumber(phoneNumber);
      if (!isPhoneValid) {
        setFieldError("telephone", "Please enter a valid Canadian mobile number.");
        return;
      }

      const data = {
        ...formState,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.user_type === 2 ? -1 : user_id : -1,
        application_type: formState?.stock ? 2 : 1,
        application_status: 2,
        applicant_email: formValues.email,
        telephone: formValues.telephone,
        email: formValues.email,
      };
      data.getFinancingAddPost = "yes"
      dispatch(save_post_application(data, () => onContinue(9)));

    }
    catch (error) {
      console.error(error);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsSubmitting(false);
    }

  };

  // main return
  return (

    <LoadingOverlay
      active={isSubmitting}
      spinner
      text="Processing your application..."
    >
      <div className="formWrapper">
        <h1>What is your email address and mobile number?</h1>
        <Formik
          initialValues={{
            email: formState.applicant_email || "",
            telephone: formState.telephone || "",
          }}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="optionFlex">
              <Field
                name="email"
                type="email"
                component={TextInput}
                placeholder="Email Address"
              />
              <Field
                mask={[
                  "+",
                  "1",
                  " ",
                  "(",
                  /[0-9]/i,
                  /[0-9]/i,
                  /[0-9]/i,
                  ")",
                  " ",
                  /[0-9]/,
                  /[0-9]/i,
                  /[0-9]/,
                  "-",
                  /[0-9]/,
                  /[0-9]/i,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                guide={false}
                name="telephone"
                component={TelephoneMaskedInput}
                placeholder="Mobile Number"
              />

              <div style={{ marginTop: "20px" }} className="DealerShip-Con d-flex">

                <label className="containerr"> {"By clicking Submit, I agree to the  "}
                  <a
                    href="https://www.financethat.ca/assets/documents/Borrower-Terms-Conditions.pdf"
                    target="_blank"
                  >
                    Borrower Terms and Conditions
                  </a>

                  <span className="checkmarkk"></span>
                </label>

              </div>
              {/* </>)} */}

            </div>

            <button
              disabled={otp_loading || isLoading}
              type="submit"
              className="primaryButton continueBtn"
            >
              {(otp_loading || isLoading) ? (
                <>
                  <span> </span>
                  <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                  <span> </span>
                </>
              ) : (
                <>
                  <span></span> <span>{
                    "Submit"
                  }</span>
                  <img
                    src="/assets/image/arrow_circle_right_outline.svg"
                    alt="icon"
                  />
                </>
              )}
            </button>
          </Form>
        </Formik>
      </div>
    </LoadingOverlay>
  );
}
