export async function validateEmailOnPortal(email) {

    const userId = localStorage.getItem("userId");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        email: email,
        user_id: userId
    });

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch("https://financethat.ca/api/validate-email/", requestOptions);

        // if (!response.ok) {
        //     return {
        //         status: false,
        //         message: 'Invalid email.'
        //     };
        // }

        const data = await response.json(); // Parse the JSON response

        return {
            status: data.valid,
            message: data.reason
        };
    } catch (error) {
        return { 
            status: false,
            message: 'Invalid email'
        };
    }
}


