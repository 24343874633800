import axios from "../../../../_helpers/axiosInterceptors";
import { getMonth, getYear } from "date-fns";
import dateFormat from "dateformat";
import $ from "jquery";
import range from "lodash/range";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import "react-rangeslider/lib/index.css";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import MaskedInput from "react-text-mask";
import { API_URL } from "../../../../constant";
import { history } from "../../../../_helpers";
import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin";


/** Add Loader */
import LoadingOverlay from 'react-loading-overlay';


/**
 * Mange renderable state for server side validation errors
 */
export class ServerValidation {
  validations = {};

  constructor(validations) {
    this.validations = validations;
  }

  getValidationAsString(key) {
    if (key in this.validations) {
      if (this.validations[key].length > 0) {
        return this.validations[key].join("<br>");
      }
    }
    return null;
  }

  setValidations(validation) {
    this.validations = validation;
  }
}

export const scrollToTop = () => {
  document.querySelector('.app-form-main').scrollIntoView();
}

export const scrollToValidationError = () => {
  setTimeout(() => {
    const firstValidationErrorOnDom = document.querySelector('.text-danger:not(:empty)');
    if (firstValidationErrorOnDom) {
      firstValidationErrorOnDom.scrollIntoView({ block: 'center' })
    }
  }, 200)

}

const validateForm = (obj_validating_keys) => {

  let is_valid = true
  Object.values(obj_validating_keys).forEach((value) => {
    if (value === false) {
      is_valid = false
    }
  });
  return is_valid;
};

var that;
class BuyerApplicationDetail extends Component {

  componentDidMount() {
    setTimeout(() => {
      if (this.props.errors_employment_information_form.is_employment_information_form_validated) {
        this.changeScreen("employment-information");
      }
    }, 100);
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      screenName: "personal-detail",
      serverValidations: new ServerValidation({})
    };
    that = this;
    $("#personal-detail").removeClass("tabDeactive");
    $("#employment-information").addClass("tabDeactive");
  }
  extractDigits = (string = '') => {
    if (string.length > 0) {
      return string.replace(/\D/g, '');
    } else {
      return '';
    }
  }
  changeScreen = (para) => {
    if (para === "personal-detail") {
      $("#personal-detail").addClass("active");
      $("#personal-detail").addClass("show");
      $("#personal-detail").removeClass("tabDeactive");
      $("#employment-information").addClass("tabDeactive");
      $("#employment-information").removeClass("active");
      $("#employment-information").removeClass("show");
    } else {
      $("#personal-detail").removeClass("active");
      $("#personal-detail").removeClass("show");
      $("#employment-information").removeClass("tabDeactive");
      $("#personal-detail").addClass("tabDeactive");
      $("#employment-information").addClass("active");
      $("#employment-information").addClass("show");
    }
    this.setState({
      ...this.state,
      screenName: para,
    });

    scrollToTop();

  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.updateBuyerApplicationPersonal !==
      this.props.updateBuyerApplicationPersonal &&
      this.props.updateBuyerApplicationPersonal !== undefined
    ) {
      $("#personal-detail").addClass("tabDeactive");
      $("#personal-detail").removeClass("active");
      $("#personal-detail").removeClass("show");
      $("#employment-information").removeClass("tabDeactive");
      $("#employment-information").addClass("active");
      $("#employment-information").addClass("show");
      this.setState({
        ...this.state,
        screenName: "employment-information",
      });
    }
    if (
      prevProps.updateBuyerApplicationEmployement !==
      this.props.updateBuyerApplicationEmployement &&
      this.props.updateBuyerApplicationEmployement !== undefined
    ) {
      if (this.props.coApplicant === true) {
        this.props.onClickChangeStep(1, 2);
        this.props.history.push(`${this.props.url}/co-applicant`);
      } else {
        this.props.onClickChangeStep(1, 3);
        this.props.history.push(`${this.props.url}/assets-detail`);
      }
    }
  }
  onBack = () => {
    $("#personal-detail").removeClass("tabDeactive");
    $("#personal-detail").addClass("active");
    $("#personal-detail").addClass("show");
    $("#employment-information").addClass("tabDeactive");
    $("#employment-information").removeClass("active");
    $("#employment-information").removeClass("show");
    this.setState({
      ...this.state,
      screenName: "personal-detail",
    });
  };

  convert_to_string = (val) => {
    if (typeof val === "undefined") {
      return "";
    } else if (val === null) {
      return "";
    } else if (typeof val === "boolean") {
      return val ? "" : "";
    } else if (typeof val === "string") {
      return val;
    } else {
      return val
    }
  }

  render() {
    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const { Option } = components;

    const renderScrollbar = (props) => {
      return (
        <div style={{ height: 260 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderScrollbar2 = (props) => {
      return (
        <div style={{ height: 225 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderScrollbarTypeEmployee = (props) => {
      return (
        <div style={{ height: 150 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderScrollbarOtherIncome = (props) => {
      return (
        <div style={{ height: 100 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const renderOption = (props) => {
      return (
        <Option {...props}>
          <div>{props.data.label}</div>
        </Option>
      );
    };
    const renderScrollbarSalutation = (props) => {
      return (
        <div style={{ height: 150 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    const horizontalLabels = {
      // grossMonthlyIncomeSlider
      0: "$0",
      [`${this.props.grossMonthlyIncomeSlider !== null &&
        this.props.grossMonthlyIncomeSlider !== ""
        ? this.props.grossMonthlyIncomeSlider
        : 150000
        }`]: `${this.props.grossMonthlyIncomeSlider !== null &&
          this.props.grossMonthlyIncomeSlider !== ""
          ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(this.props.grossMonthlyIncome)) // '$100.00'
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(0)
        }`,
      300000: `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(300000)} `,
    };
    const horizontalLabelsPrevious = {
      0: "$0",
      [`${this.props.previousGrossIncomeSlider !== null &&
        this.props.previousGrossIncomeSlider !== ""
        ? this.props.previousGrossIncomeSlider
        : 150000
        }`]: `${this.props.previousGrossIncomeSlider !== null &&
          this.props.previousGrossIncomeSlider !== ""
          ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(this.props.previousGrossIncome)) // '$100.00'
          : new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(0)
        }`,
      300000: `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(300000)} `,
    };
    let first = this.props.applicantFirstName
      ? this.props.applicantFirstName.split(" ")[0]
      : "";
    let last = this.props.applicantLastName
      ? this.props.applicantLastName.split(" ")[0]
      : "";
    first = first ? first.charAt(0).toUpperCase() : "";
    last = last ? last.charAt(0).toUpperCase() : "";

    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text={"loading..."}
        >

        <div className="app-form-content">
          <div className="app-form-content-inner">
            <ApplicationHeaderAdmin {...this.props} />
            <ul
              className="nav nav-tabs-2 admin-tabs three-items text-left"
              id="formAppTabs"
              role="tablist"
            >
              <li
                className="nav-item"
                onClick={() => this.changeScreen("personal-detail")}
              >
                <a
                  className={
                    this.state.screenName === "personal-detail"
                      ? "nav-link active pl-5"
                      : "nav-link pl-5"
                  }
                  id="personal-detail-tab"
                  data-toggle="tab"
                  href="#personal-detail"
                  role="tab"
                  aria-controls="personal-detail"
                  aria-selected={
                    this.state.screenName === "personal-detail"
                      ? "true"
                      : "false"
                  }
                >
                  {" "}
                  <span className="tabs-text personal-detail">
                    {" "}
                    Personal Detail{" "}
                  </span>{" "}
                </a>
              </li>
              <li
                className={`nav-item`}
                onClick={() => {
                  if (this.props.errors_personal_detail_form.is_personal_detail_form_validated) {
                    this.changeScreen("employment-information")
                  } else {
                    alert('Please complete personal details')
                  }
                }}
              >

                {
                  this.props.errors_personal_detail_form.is_personal_detail_form_validated ? <a
                    className={
                      this.state.screenName === "employment-information"
                        ? "nav-link active pl-5"
                        : "nav-link pl-5"
                    }
                    id="employment-information-tab"
                    data-toggle="tab"
                    href="#employment-information"
                    role="tab"
                    aria-controls="employment-information"
                    aria-selected={
                      this.state.screenName === "employment-information"
                        ? "true"
                        : "false"
                    }
                  >
                    {" "}
                    <span className="tabs-text employment-information">
                      {" "}
                      Employment Information{" "}
                    </span>{" "}
                  </a> : <a
                    className={
                      this.state.screenName === "employment-information"
                        ? "nav-link active pl-5"
                        : "nav-link pl-5"
                    }
                    href="#employment-information"
                  >
                    {" "}
                    <span className="tabs-text employment-information">
                      {" "}
                      Employment Information{" "}
                    </span>{" "}
                  </a>
                }

              </li>
            </ul>



            <div className="tab-content" id="formAppTabsContent">

              {/* Personal Detail - form */}
              <div
                className="tab-pane fade active show clearfix salman3"
                id="personal-detail"
                role="tabpanel"
                aria-labelledby="personal-detail-tab"
              >
                {this.state.screenName === "personal-detail" ? (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      {(this.props.applicantFirstName ||
                        this.props.applicantLastName) && (
                          <h1 className="float-left">
                            {`${this.props.applicantFirstName != undefined &&
                              this.props.applicantFirstName != null
                              ? this.props.applicantFirstName || ""
                              : ""
                              } ${this.props.applicantLastName != undefined &&
                                this.props.applicantLastName != null
                                ? this.props.applicantLastName || ""
                                : ""
                              }${this.props.applicantFirstName != undefined &&
                                this.props.applicantFirstName != null
                                ? "'s Details"
                                : ""
                              } `}
                          </h1>
                        )}

                      {(!this.props.coApplicant && !this.props.addAppLication) ? (
                        <Link
                          className="nav-link"
                          to={`${this.props.url}/co-applicant`}
                          onClick={() => this.props.onClickChangeStep(1, 2)}
                        >
                          {" "}
                          <button className=" AddCobtn float-right">
                            <i className="fa fa-plus-circle-fill">
                              {" "}
                              {""} Add co-applicant
                            </i>
                          </button>
                        </Link>
                      ) : null}
                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Salutation<em>*</em></label>
                          <Select
                            required
                            placeholder="Select Salutation"
                            id="selectSalutation"
                            name="salutation"
                            options={this.props.salutations}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "salutation",
                                "selectSalutation"
                              )
                            }
                            value={this.props.selectSalutation}
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbarSalutation,
                            }}
                            captureMenuScroll={false}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.salutation}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('salutation')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> First Name<em>*</em></label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            name="applicantFirstName"
                            placeholder="First Name"
                            value={this.props.applicantFirstName}
                            onChange={this.props.handleOnChangePersonalDetail}
                            onBlur={this.props.handleOnBlurPersonalDetail}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantFirstName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantFirstName')}</span>

                        </div>
                        <div className="form-field-col">
                          <label> Last Name<em>*</em></label>
                          <input
                            type="text"
                            className="form-control"
                            name="applicantLastName"
                            placeholder="Last Name"
                            value={this.props.applicantLastName}
                            onChange={this.props.handleOnChangePersonalDetail}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantLastName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantLastName')}</span>

                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Date of Birth<em>*</em></label>
                          <DatePicker
                            required
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                  type="button"
                                >
                                  {"<"}
                                </button>
                                <select
                                  value={getYear(date)}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={months[getMonth(date)]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                  type="button"
                                >
                                  {">"}
                                </button>
                              </div>
                            )}
                            selected={this.props.applicantDateOfBirth}
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            onChange={(e) =>
                              this.props.handleOnChangeDate(
                                e,
                                "applicantDateOfBirth"
                              )
                            }
                            maxDate={new Date()}
                            name="applicantDateOfBirth"
                            className="form-control callendar"
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantDateOfBirth}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantDateOfBirth')}</span>
                        </div>
                        <div className="form-field-col">
                          <label> Mobile Number<em>*</em></label>
                          <NumberFormat
                            required
                            className="form-control"
                            format="+1 (###) ###-####"
                            placeholder="+1 (123) 456-7890"
                            onChange={this.props.handleOnChangePersonalDetail}
                            value={this.props.applicantTelephone} //change pop firs
                            name="applicantTelephone"
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantTelephone}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('phone_number')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label> Gender </label>
                          <Select
                            required
                            placeholder="Select Gender"
                            id="selectGender"
                            name="gender"
                            options={this.props.genders}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "gender",
                                "selectGender"
                              )
                            }
                            value={this.props.selectGender}
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbarTypeEmployee,
                            }}
                            captureMenuScroll={false}
                          />
                        </div>
                        <div className="form-field-col selectSalutation">
                          <label>Marital Status<em>*</em></label>
                          <Select
                            required
                            placeholder="Select Marital Status"
                            id="selectApplicantMaritalStatus"
                            name="applicantMaritalStatus"
                            options={this.props.maritalStatues}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "applicantMaritalStatus",
                                "selectApplicantMaritalStatus"
                              )
                            }
                            value={this.props.selectApplicantMaritalStatus}
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbarTypeEmployee,
                            }}
                            captureMenuScroll={false}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantMaritalStatus}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantMaritalStatus')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Social insurance number (optional) </label>
                          <NumberFormat
                            required
                            className="form-control"
                            value={this.props.applicantSin}
                            onChange={this.props.handleOnChangePersonalDetail}
                            allowNegative={false}
                            id="applicantSin"
                            name="applicantSin"
                            placeholder="Sin"
                          />
                        </div>
                        <div className="form-field-col">
                          <label> Email<em>*</em></label>
                          <input
                            type="email"
                            className="form-control"
                            name="applicantEmail"
                            placeholder="Email"
                            value={this.props.applicantEmail}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={!this.props.addAppLication}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantEmail}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('email')}</span>
                        </div>
                      </div>
                      <h1 className="mt-5 pt-2" style={{ fontSize: "18px" }}>
                        Current Address
                      </h1>
                      <div className="form-field-row clearfix">
                        <div className="form-field-col">
                          <label> Street Address<em>*</em></label>

                          <input
                            type="text"
                            className="form-control"
                            name="applicantStreetAddress"
                            placeholder="Street Address"
                            value={this.props.applicantStreetAddress}
                            onChange={this.props.handleOnChangePersonalDetail}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantStreetAddress}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('address')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> City<em>*</em></label>
                          <input
                            required
                            type="text"
                            id="applicantCity"
                            name="applicantCity"
                            placeholder="City"
                            className="form-control"
                            onChange={this.props.handleOnChangePersonalDetail}
                            value={this.props.applicantCity}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantCity}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('city')}</span>
                        </div>
                        <div className="form-field-col selectSalutation">
                          <label> Province<em>*</em></label>
                          <Select
                            required
                            placeholder="Select Province"
                            id="selectedProvince"
                            name="applicantProvince"
                            options={this.props.provinces}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "applicantProvince",
                                "selectedProvince"
                              )
                            }
                            value={this.props.selectedProvince}
                            className="react-select-main "
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbar,
                            }}
                            captureMenuScroll={false}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantProvince}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('province')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Postal Code<em>*</em></label>
                          <MaskedInput
                            mask={[
                              /[a-zA-Z0-9]/i,
                              /[a-zA-Z0-9]/,
                              /[a-zA-Z0-9]/i,
                              " ",
                              /[a-zA-Z0-9]/,
                              /[a-zA-Z0-9]/i,
                              /[a-zA-Z0-9]/,
                            ]}
                            className="form-control"
                            guide={false}
                            placeholder="12345"
                            id="applicantPostalCode"
                            name="applicantPostalCode"
                            value={this.props.applicantPostalCode}
                            onChange={this.props.handleOnChangePersonalDetail}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantPostalCode}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('postal_code')}</span>
                        </div>
                        <div className="form-field-col">
                          <label> Country<em>*</em></label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="applicantCountry"
                            name="applicantCountry"
                            placeholder="Country"
                            value={this.props.applicantCountry}
                            onChange={this.props.handleOnChangePersonalDetail}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantCountry}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('country')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Years at current address<em>*</em></label>
                          <Select
                            required
                            placeholder="Select Years at Current Address"
                            options={[
                              { label: "1 year", value: "1" },
                              { label: "2 years", value: "2" },
                              { label: "3 years", value: "3" },
                              { label: "4 years", value: "4" },
                              { label: "5 years or more", value: "5" },
                              { label: "6-12 months", value: "6" },
                              { label: "less than 6 months", value: "7" }
                            ]}
                            onChange={(e) =>
                              this.props.handleOnChangeYears(
                                e,
                                "duration_address_yr"
                              )
                            }
                            value={
                              [
                                { label: "1 year", value: "1" },
                                { label: "2 years", value: "2" },
                                { label: "3 years", value: "3" },
                                { label: "4 years", value: "4" },
                                { label: "5 years or more", value: "5" },
                                { label: "6-12 months", value: "6" },
                                { label: "less than 6 months", value: "7" }
                              ].filter(
                                (item) =>
                                  item.value === this.props.duration_address_yr
                              )?.[0]
                            }
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbarTypeEmployee,
                            }}
                            captureMenuScroll={false}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.duration_address_yr}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('years_at_current_address')}</span>
                        </div>
                        <div className="form-field-col selectSalutation">
                          <label> Status<em>*</em></label>
                          <Select
                            required
                            placeholder="Select Status"
                            id="selectedStatus"
                            name="status"
                            options={this.props.statuses}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "status",
                                "selectedStatus"
                              )
                            }
                            value={this.props.selectedStatus}
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbarTypeEmployee,
                            }}
                            captureMenuScroll={false}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.status}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('do_you_own_or_rent')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Mortgage or rent amount </label>
                          <NumberFormat
                            required
                            className="form-control"
                            value={this.props.mortgage_amount}
                            onChange={this.props.handleOnChangePersonalDetail}
                            allowNegative={false}
                            id="mortgage_amount"
                            name="mortgage_amount"
                            placeholder="Amount"
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.mortgage_amount}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('amount')}</span>
                        </div>
                      </div>
                      {this.props.duration_address_yr &&
                        this.props.duration_address_yr < 2 && (
                          <>
                            <h1
                              className="mt-5 pt-2"
                              style={{ fontSize: "18px" }}
                            >
                              Previous Address
                            </h1>
                            <div className="form-field-row clearfix">
                              <div className="form-field-col">
                                <label> Street Address </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="applicantPreviousStreetAddress"
                                  placeholder="Previous Street Address"
                                  value={
                                    this.props.applicantPreviousStreetAddress
                                  }
                                  onChange={this.props.handleOnChangePersonalDetail}
                                />
                              </div>
                            </div>
                            {/* <div className="form-field-row clearfix">
                              <div className="form-field-col">
                                <label> Street Address Line 2 </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="applicantPreviousStreetAddress2"
                                  placeholder="Previous Street Address Line 2 (Suite, unit etc...)"
                                  value={
                                    this.props.applicantPreviousStreetAddress2
                                  }
                                  onChange={this.props.handleOnChangePersonalDetail}
                                />
                              </div>
                            </div> */}
                            <div className="form-field-row two-col clearfix">
                              <div className="form-field-col">
                                <label> City </label>
                                <input
                                  type="text"
                                  id="previousCity"
                                  name="previousCity"
                                  placeholder="City"
                                  className="form-control"
                                  onChange={this.props.handleOnChangePersonalDetail}
                                  value={this.props.previousCity}
                                />
                              </div>
                              <div className="form-field-col selectSalutation">
                                <label> Province </label>
                                <Select
                                  placeholder="Select Province"
                                  id="selectedPreviousProvince"
                                  name="previousProvince"
                                  options={this.props.provinces}
                                  onChange={(e) =>
                                    this.props.changeSelect(
                                      e,
                                      "previousProvince",
                                      "selectedPreviousProvince"
                                    )
                                  }
                                  value={this.props.selectedPreviousProvince}
                                  className="react-select-main "
                                  classNamePrefix="react-select"
                                  components={{
                                    Option: renderOption,
                                    MenuList: renderScrollbar,
                                  }}
                                  captureMenuScroll={false}
                                />
                              </div>
                            </div>
                            <div className="form-field-row two-col clearfix">
                              <div className="form-field-col">
                                <label> Postal Code </label>
                                <MaskedInput
                                  mask={[
                                    /[a-zA-Z0-9]/i,
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/i,
                                    " ",
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/i,
                                    /[a-zA-Z0-9]/,
                                  ]}
                                  className="form-control"
                                  guide={false}
                                  placeholder="12345"
                                  id="previousPostalCode"
                                  name="previousPostalCode"
                                  value={this.props.previousPostalCode}
                                  onChange={this.props.handleOnChangePersonalDetail}
                                />
                              </div>
                              <div className="form-field-col">
                                <label> Country </label>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  disabled="disabled"
                                  id="previousCountry"
                                  name="previousCountry"
                                  placeholder="Country"
                                  value="Canada"
                                  onChange={this.props.handleOnChangePersonalDetail}
                                />
                              </div>
                            </div>
                            <div className="form-field-row two-col clearfix">
                              <div className="form-field-col">
                                <label>Years at previous address </label>
                                <NumberFormat
                                  required
                                  className="form-control"
                                  value={this.props.durationAtPreviousAddress}
                                  onChange={this.props.handleOnChangePersonalDetail}
                                  allowNegative={false}
                                  id="durationAtPreviousAddress"
                                  name="durationAtPreviousAddress"
                                  placeholder="Years"
                                />
                              </div>
                              <div className="form-field-col">
                                <label> Months at previous address </label>
                                <NumberFormat
                                  required
                                  className="form-control"
                                  value={
                                    this.props.durationAtPreviousAddressMonth
                                  }
                                  onChange={this.props.handleOnChangePersonalDetail}
                                  allowNegative={false}
                                  id="durationAtPreviousAddressMonth"
                                  name="durationAtPreviousAddressMonth"
                                  placeholder="Months"
                                />
                              </div>
                            </div>
                            <div className="form-field-row two-col clearfix">
                              <div className="form-field-col selectSalutation">
                                <label> Status </label>
                                <Select
                                  required
                                  placeholder=""
                                  id="previousSelectedStatus"
                                  name="previousStatus"
                                  options={this.props.statuses}
                                  onChange={(e) =>
                                    this.props.changeSelect(
                                      e,
                                      "previousStatus",
                                      "previousSelectedStatus"
                                    )
                                  }
                                  value={this.props.previousSelectedStatus}
                                  className="react-select-main"
                                  classNamePrefix="react-select"
                                  components={{
                                    Option: renderOption,
                                    MenuList: renderScrollbarTypeEmployee,
                                  }}
                                  captureMenuScroll={false}
                                />
                              </div>
                              <div className="form-field-col">
                                <label> Mortgage or rent amount </label>
                                <NumberFormat
                                  required
                                  className="form-control"
                                  value={this.props.previousMortgageAmount}
                                  onChange={this.props.handleOnChangePersonalDetail}
                                  allowNegative={false}
                                  id="previousMortgageAmount"
                                  name="previousMortgageAmount"
                                  placeholder="Amount"
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    {!this.props.addAppLication && (<div className="switch-holder">
                      <input
                        id="swicth"
                        type="checkbox"
                        name="personal_complete"
                        checked={this.props.personal_complete}
                        onChange={(e) =>
                          this.props.changeCompleteStatus(
                            e,
                            "personal_complete"
                          )
                        }
                      />
                      <label for="swicth" className="switch">
                        <div></div>
                      </label>
                      <span className="switch-label"> Complete </span>
                    </div>)}

                    <div className="footer-btns-holder clearfix">
                      {/* <Link to={this.props.mainUrl}>
                        <button className="btn btn-primary float-left">
                          {" "}
                          Back{" "}
                        </button>
                      </Link> */}
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          {" "}
                          <i
                            className="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary float-right active"
                          onClick={async (event) => {

                            this.setState({isLoading: true});
                            
                            try{

                              scrollToValidationError();
                              event.preventDefault();

                              this.props.validateOnChangePersonalDetail('salutation', this.props.salutation);
                              this.props.validateOnChangePersonalDetail('applicantFirstName', this.props.applicantFirstName);
                              this.props.validateOnChangePersonalDetail('applicantLastName', this.props.applicantLastName);
                              this.props.validateOnChangePersonalDetail('applicantDateOfBirth', this.props.applicantDateOfBirth);
                              this.props.validateOnChangePersonalDetail('applicantTelephone', this.props.applicantTelephone);
                              this.props.validateOnChangePersonalDetail('applicantMaritalStatus', this.props.applicantMaritalStatus);
                              this.props.validateOnChangePersonalDetail('applicantEmail', this.props.applicantEmail);
                              this.props.validateOnChangePersonalDetail('applicantStreetAddress', this.props.applicantStreetAddress);
                              this.props.validateOnChangePersonalDetail('applicantCity', this.props.applicantCity);
                              this.props.validateOnChangePersonalDetail('applicantProvince', this.props.applicantProvince);
                              this.props.validateOnChangePersonalDetail('applicantPostalCode', this.props.applicantPostalCode);
                              this.props.validateOnChangePersonalDetail('applicantCountry', this.props.applicantCountry);
                              this.props.validateOnChangePersonalDetail('duration_address_yr', this.props.duration_address_yr);
                              this.props.validateOnChangePersonalDetail('status', this.props.status);

                              // Mobile phone validation
                              if (this.props.applicantTelephone?.trim()) {
                                await this.props.validatePersonalMobileNumberWithAbstractAPI();
                              }
                              
                              if (this.props.validation_personal_detail_form.applicantEmail === true) {
                                await this.props.validateApplicantEmail();
                              }

                              if (validateForm(this.props.validation_personal_detail_form)) {

                                const payload = {
                                  salutation: this.props.salutation,
                                  first_name: this.props.applicantFirstName,
                                  last_name: this.props.applicantLastName,
                                  date_of_birth: dateFormat(this.props.applicantDateOfBirth, "yyyy-mm-dd"),
                                  phone_number: this.extractDigits(this.props.applicantTelephone),
                                  marital_status: this.props.applicantMaritalStatus,
                                  email: this.props.applicantEmail,
                                  address: this.props.applicantStreetAddress,
                                  sin: this.props.applicantSin,
                                  city: this.props.applicantCity,
                                  province: this.props.applicantProvince,
                                  postal_code: this.props.applicantPostalCode,
                                  country: this.props.applicantCountry,
                                  years_at_current_address: this.props.duration_address_yr,
                                  do_you_own_or_rent: this.props.status,
                                  amount: this.props.mortgage_amount,
                                  application_type: 3,
                                  form_identity: 1,
                                  is_just_validation: true,
                                  gender: this.props.gender,
                                  user: window.localStorage.getItem('userId'),
                                }

                                if (this.props.duration_address_yr === "-1") {
                                  payload.previous_address_detail = {
                                    city: this.convert_to_string(this.props.previousCity),
                                    country: this.convert_to_string(this.props.previousCountry),
                                    duration_address_mn: this.props.durationAtPreviousAddressMonth,
                                    duration_address_yr: this.props.durationAtPreviousAddress,
                                    mortgage_amount: this.props.previousMortgageAmount,
                                    postal_code: this.props.previousPostalCode,
                                    province: this.props.previousProvince,
                                    status: this.props.previousStatus,
                                    street_address: this.props.applicantPreviousStreetAddress,
                                  }
                                }

                                if (this.props.operation_mode === 'create') {
                                  axios.post(`${API_URL}/manual-application/`, payload).then(res => {
                                    this.setState((prevState) => {
                                      prevState.serverValidations.setValidations(res.data.errors);
                                      return prevState;
                                    })
                                    if (res.data.errors.length === 0) {
                                      this.props.errors_personal_detail_form.is_personal_detail_form_validated = true;
                                      this.changeScreen("employment-information");
                                    } else {
                                      scrollToValidationError();
                                    }
                                  })
                                } else if (this.props.operation_mode === 'edit') {
                                  const applicationId = this.props.match.params.id;
                                  payload.is_just_validation = true;
                                  axios.put(`${API_URL}/manual-application/${applicationId}/`, payload).then(res => {
                                    toastr.success("Success", "Details successfully updated");
                                    this.props.errors_personal_detail_form.is_personal_detail_form_validated = true;
                                  })
                                }
                              } else {
                                this.props.errors_personal_detail_form.is_personal_detail_form_validated = false;
                              }

                            } catch (error) {
                              console.log("error while processing. ", error);
                            } finally {
                              this.setState({isLoading: false});                          
                            }

                          }}
                        >
                          {" "}
                          Save{" "}
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>



              {/* Employment Information - form */}
              <div
                className="tab-pane fade"
                id="employment-information"
                role="tabpanel"
                aria-labelledby="employment-information-tab"
              >
                {this.state.screenName === "employment-information" ? (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      <h1 className="">Current Employment Information</h1>
                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label> Employment Status<em>*</em></label>
                          <Select
                            required
                            placeholder="Select Employment Status"
                            id="selectEmploymentStatus"
                            name="employmentStatus"
                            options={this.props.employmentStatuses}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "employmentStatus",
                                "selectEmploymentStatus"
                              )
                            }
                            value={this.props.selectEmploymentStatus}
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbar2,
                            }}
                            captureMenuScroll={false}
                          />

                          <span className='text-danger'>{this.props.errors_employment_information_form.employmentStatus}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employement_status')}</span>

                        </div>
                        {this.props.employmentStatus !== "Unemployed" &&
                          this.props.employmentStatus !== "Retired" ? (
                          this.props.employmentStatus === "Self employed" ? (
                            <div className="form-field-col">
                              <label> Business Name </label>
                              <input
                                type="text"
                                className="form-control"
                                name="employeeBusinessName"
                                placeholder="Business Name"
                                value={this.props.employeeBusinessName}
                                onChange={this.props.handleOnChange}
                              />
                            </div>
                          ) : (
                            <div className="form-field-col selectSalutation">
                              <label> Type of Employment<em>*</em></label>
                              <Select
                                placeholder="Select Type of Employment"
                                id="selectedTypeOfEmployment"
                                name="typeOfEmployment"
                                options={this.props.typeOfEmployments}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "typeOfEmployment",
                                    "selectedTypeOfEmployment"
                                  )
                                }
                                value={this.props.selectedTypeOfEmployment}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbarTypeEmployee,
                                }}
                                captureMenuScroll={false}
                              />
                              <span className='text-danger'>{this.props.errors_employment_information_form.typeOfEmployment}</span>
                              <span className='text-danger'>{this.state.serverValidations.getValidationAsString('type_of_employment')}</span>

                            </div>
                          )
                        ) : null}
                      </div>
                      {this.props.employmentStatus === "Unemployed" ||
                        this.props.employmentStatus === "Retired" ||
                        this.props.employmentStatus === "Self employed" ? null : (
                        <div className="form-field-row two-col clearfix">
                          <div className="form-field-col">
                            <label> Employer Name<em>*</em></label>
                            <input
                              type="text"
                              className="form-control"
                              name="employerName"
                              placeholder="Employee Name"
                              value={this.props.employerName}
                              onChange={this.props.handleOnChangeEmploymentInformation}
                            />

                            <span className='text-danger'>{this.props.errors_employment_information_form.employerName}</span>
                            <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employer_name')}</span>
                          </div>
                          <div className="form-field-col">
                            <label> Occupation<em>*</em></label>
                            <input
                              type="text"
                              className="form-control"
                              name="occupation"
                              placeholder="Occupation"
                              value={this.props.occupation}
                              onChange={this.props.handleOnChangeEmploymentInformation}
                            />

                            <span className='text-danger'>{this.props.errors_employment_information_form.occupation}</span>
                            <span className='text-danger'>{this.state.serverValidations.getValidationAsString('occupation')}</span>
                          </div>
                        </div>
                      )}

                      <div className="form-field-row two-col clearfix">
                        {this.props.employmentStatus === "Unemployed" ||
                          this.props.employmentStatus === "Retired" ? null : (
                          <div className="form-field-col">
                            <label>
                              {" "}
                              {this.props.employmentStatus === "Self employed"
                                ? "Business start date"
                                : "Employment since"}{" "}<em>*</em>
                            </label>
                            <DatePicker
                              required
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                    type="button"
                                  >
                                    {"<"}
                                  </button>
                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                    dateFormat="YYYY-MM-DD"
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                    type="button"
                                  >
                                    {">"}
                                  </button>
                                </div>
                              )}
                              selected={this.props.employmentSince}
                              placeholderText="YYYY-MM-DD"
                              dateFormat="yyyy-MM-dd"
                              onChange={(e) =>
                                this.props.handleOnChangeDates(
                                  e,
                                  "employmentSince"
                                )
                              }
                              name="employmentSince"
                              maxDate={new Date()}
                              className="form-control callendar"
                            />
                            <span className='text-danger'>{this.props.errors_employment_information_form.employmentSince}</span>
                            <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employment_since')}</span>
                          </div>
                        )}
                        {this.props.employmentStatus === "Unemployed" ? null : (
                          <div className="form-field-row two-col clearfix">
                            <div className="form-field-col selectSalutation">
                              <label>Monthly Income<em>*</em></label>
                              <Select
                                required
                                placeholder=""
                                id="monthlySelectedGrossIncome"
                                name="monthlyGrossIncome"
                                options={this.props.grossMonthlyIncomeValues}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "monthlyGrossIncome",
                                    "monthlySelectedGrossIncome"
                                  )
                                }
                                value={this.props.monthlySelectedGrossIncome}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbar2,
                                }}
                                captureMenuScroll={false}
                              />
                              <span className='text-danger'>{this.props.errors_employment_information_form.monthlyGrossIncome}</span>
                              <span className='text-danger'>{this.state.serverValidations.getValidationAsString('monthly_income')}</span>
                            </div>
                            <div className="form-field-col"></div>
                          </div>
                        )}
                        {this.props.employmentStatus !==
                          "Self employed" ? null : (
                          <div className="form-field-col">
                            <label> Type of Business </label>
                            <input
                              type="text"
                              className="form-control"
                              name="typeOfBusniess"
                              placeholder="Type of Business"
                              value={this.props.typeOfBusniess}
                              onChange={this.props.handleOnChange}
                            />
                          </div>
                        )}
                      </div>
                      {this.props.employmentStatus === "Retired" ||
                        (this.props.employmentStatus !== "Unemployed" && (
                          <>
                            {" "}
                            <div className="PostApp-Form mt-5">
                              <div className="py-2">
                                <h4> Current Employer Address </h4>
                              </div>
                              <div className="clearfix ">
                                <label>Employer Street Address</label>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  id="employerStreetAddress"
                                  name="employerStreetAddress"
                                  placeholder=""
                                  value={this.props.employerStreetAddress}
                                  onChange={this.props.handleOnChange}
                                />
                              </div>
                            </div>
                            <div className="form-field-row two-col clearfix">
                              <div className="form-field-col">
                                <label>Employer City</label>
                                <input
                                  type="text"
                                  id="employerCity"
                                  name="employerCity"
                                  className="form-control"
                                  placeholder=""
                                  value={this.props.employerCity}
                                  onChange={this.props.handleOnChange}
                                />
                              </div>
                              <div className="form-field-col selectSalutation">
                                <label>Employer Province</label>
                                <Select
                                  required
                                  placeholder="Select Province"
                                  id="selectedEmployerProvince"
                                  name="selectedEmployerProvince"
                                  options={this.props.provinces}
                                  onChange={(e) =>
                                    this.props.changeSelect(
                                      e,
                                      "employerProvince",
                                      "selectedEmployerProvince"
                                    )
                                  }
                                  value={this.props.selectedEmployerProvince}
                                  className="react-select-main "
                                  classNamePrefix="react-select"
                                  components={{
                                    Option: renderOption,
                                    MenuList: renderScrollbar,
                                  }}
                                  captureMenuScroll={false}
                                />
                                <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employer_province')}</span>
                              </div>
                            </div>
                            <div className="form-field-row two-col clearfix">
                              <div className="form-field-col">
                                <label>Employer Mobile Number</label>
                                <NumberFormat
                                  required
                                  className="form-control"
                                  value={this.props.employerPhone}
                                  onChange={this.props.handleOnChange}
                                  allowNegative={false}
                                  id="employerPhone"
                                  name="employerPhone"
                                  format="+1 (###) ###-####"
                                  placeholder="+1(123)456-7890"
                                />
                                <span className='text-danger'>{this.props.errors_employment_information_form.employerPhone}</span>
                              </div>

                              <div className="form-field-col">
                                <label>Employer Email</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  id="employerEmail"
                                  name="employerEmail"
                                  placeholder=""
                                  value={this.props.employerEmail}
                                  onChange={this.props.handleOnChange}
                                />
                              </div>
                            </div>{" "}
                          </>
                        ))}
                    </div>

                    <div className="forms-head clearfix mt-2">
                      <h1 className="">Other Income</h1>
                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        {/* Other income Added by salman */}
                        <div className="form-field-col">
                          <label> Other Income Type </label>
                          <input
                            className="form-control"
                            type="email"
                            id="income_type"
                            name="income_type"
                            placeholder=""
                            value={this.props.income_type}
                            onChange={this.props.handleOnChange}
                          />
                        </div>

                        <div className="form-field-col">
                          <label>Other Income Amount</label>
                          <NumberFormat
                            required
                            className="form-control"
                            value={this.props.income_amount}
                            onChange={this.props.handleOnChange}
                            allowNegative={false}
                            id="income_amount"
                            name="income_amount"
                            placeholder=""
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Frequency</label>
                          <Select
                            required
                            placeholder=""
                            id="selectedIncomeFrequency"
                            name="income_frequency"
                            options={this.props.inComeFrequencies}
                            onChange={(e) =>
                              this.props.changeSelect(
                                e,
                                "income_frequency",
                                "selectedIncomeFrequency"
                              )
                            }
                            value={this.props.selectedIncomeFrequency}
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbarOtherIncome,
                            }}
                            captureMenuScroll={false}
                          />
                        </div>
                      </div>
                    </div>
                    {!this.props.addAppLication && (<div className="switch-holder">
                      <input
                        id="swicth"
                        type="checkbox"
                        name="employement_complete"
                        checked={this.props.employement_complete}
                        onChange={(e) =>
                          this.props.changeCompleteStatus(
                            e,
                            "employement_complete"
                          )
                        }
                      />
                      <label for="swicth" className="switch">
                        <div></div>
                      </label>
                      <span className="switch-label"> Complete </span>
                    </div>)}


                    <div className="footer-btns-holder clearfix">
                      <button
                        className="btn btn-primary float-left"
                        onClick={this.onBack}
                      >
                        {" "}
                        Back{" "}
                      </button>
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          {" "}
                          <i
                            className="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary float-right active"
                          onClick={ async(event) => {
                            event.preventDefault();
                            scrollToValidationError();

                            this.setState({isLoading: true});
                            
                            try{                            

                            this.props.validateOnChangeEmploymentInformation('employmentStatus', this.props.employmentStatus)
                            this.props.validateOnChangeEmploymentInformation('typeOfEmployment', this.props.typeOfEmployment)
                            this.props.validateOnChangeEmploymentInformation('employerName', this.props.employerName)
                            this.props.validateOnChangeEmploymentInformation('occupation', this.props.occupation)
                            this.props.validateOnChangeEmploymentInformation('employmentSince', this.props.employmentSince)
                            this.props.validateOnChangeEmploymentInformation('monthlyGrossIncome', this.props.monthlyGrossIncome)

                            await this.props.validateEmployerMobileNumberWithAbstractAPI();

                            if (validateForm(this.props.validation_employment_information_form)) {

                              const payload = {
                                employement_status: this.props.employmentStatus,
                                type_of_employment: this.props.typeOfEmployment,
                                employer_name: this.props.employerName,
                                occupation: this.props.occupation,
                                employment_since: dateFormat(this.props.employmentSince, "yyyy-mm-dd"),
                                monthly_income: (this.props.monthlyGrossIncome === undefined) ? '' : this.props.monthlyGrossIncome,
                                employer_address: this.convert_to_string(this.props.employerStreetAddress),
                                employer_city: this.convert_to_string(this.props.employerCity),
                                employer_province: this.convert_to_string(this.props.employerProvince),
                                employer_email: this.convert_to_string(this.props.employerEmail),
                                employer_telephone: this.convert_to_string(this.extractDigits(this.props.employerPhone)),
                                marital_status: "Single",
                                other_income: {
                                  income_amount: this.props.income_amount,
                                  income_frequency: this.props.income_frequency,
                                  income_type: this.props.income_type
                                },
                                application_type: 3,
                                form_identity: 2,
                                is_just_validation: true
                              }


                              if (this.props.operation_mode === 'create') {
                                axios.post(`${API_URL}/manual-application/`, payload).then(res => {

                                  this.setState((prevState) => {
                                    prevState.serverValidations.setValidations(res.data.errors);
                                    return prevState;
                                  })

                                  if (res.data.errors.length === 0) {
                                    this.props.onClickChangeNav(3)
                                    this.props.errors_employment_information_form.is_employment_information_form_validated = true;
                                    history.push(`${this.props.match.url}/assets-detail`);
                                  } else {
                                    scrollToValidationError();
                                  }

                                })

                              } else if (this.props.operation_mode === 'edit') {
                                const applicationId = this.props.match.params.id;
                                payload.is_just_validation = true;
                                axios.put(`${API_URL}/manual-application/${applicationId}/`, payload).then(res => {
                                  toastr.success("Success", "Details successfully updated");
                                  this.props.errors_employment_information_form.is_employment_information_form_validated = true;
                                })
                              }
                            } else {
                              this.props.errors_employment_information_form.is_employment_information_form_validated = false;
                            }

                          } catch (error) {
                            console.log("error while processing. ", error);
                          } finally {
                            this.setState({isLoading: false});                          
                          }                            

                          }}
                        >
                          {" "}
                          Save{" "}
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>

          </div>
        </div>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}
export default BuyerApplicationDetail;